export const initFreshChat = (others: Record<string, any> = {}) => {
  const { fcWidget } = window as any;
  if (fcWidget) {
    fcWidget.init({
      token: "059f8c4f-51bf-421a-97da-5864a79d2085",
      host: "https://wchat.freshchat.com",
      ...others,
    });
  }
};

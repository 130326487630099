import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface OrderState {
  isOrdering: boolean;
  orderProduct: Record<string, any> | null;
  orders: Record<string, any>[];
  deliveryLocations: Record<string, any>[];
  productOrdered: null | Record<string, any>;
  orderingStatus: Record<string, any> | null;
  navigate: boolean;
  sharedProducts: Record<string, any>[];
}

const initialState: OrderState = {
  isOrdering: false,
  orderProduct: null,
  orders: [],
  deliveryLocations: [],
  productOrdered: null,
  orderingStatus: null,
  navigate: false,
  sharedProducts: [],
};

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setIsOrdering: (state, action: PayloadAction<boolean>) => {
      state.isOrdering = action.payload;
    },
    setOrderProduct: (
      state,
      action: PayloadAction<Record<string, any> | null>
    ) => {
      state.orderProduct = action.payload;
    },
    setOrders: (state, action: PayloadAction<Record<string, any>[] | null>) => {
      state.orders =
        action.payload === null ? [] : [...state.orders, ...action.payload];
    },
    setDeliveryLocations: (
      state,
      action: PayloadAction<Record<string, any>[]>
    ) => {
      state.deliveryLocations = [...action.payload];
    },
    setProductOrdered: (
      state,
      action: PayloadAction<Record<string, any> | null>
    ) => {
      state.productOrdered = action.payload;
    },
    setOrderingStatus: (
      state,
      action: PayloadAction<Record<string, any> | null>
    ) => {
      state.orderingStatus = action.payload;
    },
    setNavigate: (state, action: PayloadAction<boolean>) => {
      state.navigate = action.payload;
    },
    setSharedProducts: (
      state,
      action: PayloadAction<Record<string, any>[]>
    ) => {
      state.sharedProducts = [...state.sharedProducts, ...action.payload];
    },
  },
});

export const {
  setIsOrdering,
  setOrderProduct,
  setOrders,
  setDeliveryLocations,
  setProductOrdered,
  setOrderingStatus,
  setNavigate,
  setSharedProducts,
} = orderSlice.actions;

export default orderSlice.reducer;

import React from "react";

interface Props {
  children: React.ReactNode;
}

const NavContainer = ({ children }: Props) => {
  return (
    <div
      className="w-screen fixed overflow-hidden left-0 bottom-0 inset-x-0 z-10 flex flex-col items-center bg-white"
      style={{
        height: "calc(49px + env(safe-area-inset-bottom))",
        boxShadow: "rgba(0, 0, 0, 0.2) 0px -1px 0px 0px",
        transition:
          "transform 0.2s ease 0s, height 0.2s ease 0s, opacity 0.2s ease 0s",
      }}
    >
      <div className="flex w-full justify-start">{children}</div>
    </div>
  );
};

export default NavContainer;

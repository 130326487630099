import { setToggleLogin } from "./../actions/appActions";
import { call, put } from "redux-saga/effects";
import api from "../../services/api.service";
import {
  AUTHENTICATE_USER,
  LOGIN_USER,
  LOGOUT,
  REGISTER_USER,
  REQUEST_OTP,
  REQUEST_OTP_RESET,
  UPDATE_USER_PAYMENT,
  UPDATE_USER_PROFILE,
  VERIFY_OTP,
} from "../../utils/constants";
import {
  setToggleErrorModal,
  setToggleRegister,
  setToggleSignOut,
} from "../slice/app.slice";
import {
  setAuthLoading,
  setAuthStatus,
  setIsAuthenticated,
  setUser,
  TAuthStatus,
} from "../slice/auth.slice";
import { OTPRequest } from "../actions/authActions";

function* handleError(error?: any) {
  const obj: TAuthStatus = {
    success: error.response.data.success,
    error: error.response.data.error || "Something went wrong",
    isError: true,
  };

  yield put(setAuthStatus(obj));
  yield put(setIsAuthenticated(false));
}

function* initRequest() {
  yield put(setIsAuthenticated(true));
  yield put(setAuthStatus(null));
}

function* requestDone() {
  yield put(setIsAuthenticated(false));
  yield put(setAuthStatus(null));
}

function* authSaga({ type, payload }: { type: string; payload: any }) {
  switch (type) {
    case AUTHENTICATE_USER:
      try {
        yield put(setAuthLoading(true));
        const result: Record<string, any> = yield call(
          api.getUser,
          payload,
          "token"
        );

        if (result?.data.nigeriaUsers.length === 1) {
          yield put(setUser(result?.data.nigeriaUsers[0]));
          yield put(setAuthLoading(false));
        } else {
          yield put(setUser(null));

          yield put(setToggleRegister(true));
          yield handleError({ message: "User not found" });
          yield put(setToggleErrorModal(true));
        }
      } catch (error: any) {
        yield handleError(error);
        yield put(setToggleErrorModal(true));
        yield put(setAuthLoading(false));
        yield put(setToggleRegister(true));
      } finally {
        yield put(setAuthLoading(false));
      }
      break;
    case LOGIN_USER:
      try {
        yield initRequest();
        const result: Record<string, any> = yield call(api.loginUser, payload);

        if (result?.success) {
          localStorage.setItem("resellerToken-ng-reseller", result?.data.token);
          yield put(setUser(result?.data));
          yield put(setToggleLogin(false));
          yield requestDone();
        } else {
          yield put(setUser(null));
          const obj: TAuthStatus = {
            success: false,
            error: "User not found",
            isError: true,
          };
          yield put(setAuthStatus(obj));
          yield put(setToggleErrorModal(true));
        }
      } catch (error: any) {
        yield handleError(error);
      } finally {
        yield put(setIsAuthenticated(false));
      }
      break;
    case REGISTER_USER:
      try {
        yield initRequest();

        const result: Record<string, any> = yield call(
          api.registerUser,
          payload
        );

        if (result?.success) {
          yield put(setUser(result?.data));
          localStorage.setItem("resellerToken-ng-reseller", result?.data.token);
          yield put(OTPRequest({ phone: payload.phone, email: payload.email }));
          yield requestDone();
        }
      } catch (error) {
        yield handleError(error);
      } finally {
        yield put(setIsAuthenticated(false));
      }
      break;
    case LOGOUT:
      yield put(setUser(null));
      yield put(setToggleSignOut(false));
      localStorage.removeItem("resellerToken-ng-reseller");
      break;
    case UPDATE_USER_PROFILE:
      try {
        yield initRequest();
        const result: Record<string, any> = yield call(
          api.updateUserData,
          payload
        );
        // console.log(result); // debugger;
        if (result?.success) {
          const obj: TAuthStatus = {
            success: true,
            error: "Update successful",
            isError: false,
          };

          yield put(setIsAuthenticated(false));
          yield put(setAuthStatus(obj));
        }
      } catch (error) {
        yield handleError(error);
        yield put(setToggleErrorModal(true));
      } finally {
        yield put(setIsAuthenticated(false));
      }

      break;
    case UPDATE_USER_PAYMENT:
      try {
        yield initRequest();
        const result: Record<string, any> = yield call(
          api.updateUserData,
          payload
        );
        // console.log(result); // debugger;
        if (result?.success) {
          const obj: TAuthStatus = {
            success: true,
            error: "Payment details update successful",
            isError: false,
          };
          yield put(setIsAuthenticated(false));
          yield put(setAuthStatus(obj));
        }
      } catch (error) {
        console.log(error);
        yield handleError(error);
        yield put(setToggleErrorModal(true));
        yield put(setIsAuthenticated(false));
      } finally {
        yield put(setIsAuthenticated(false));
      }
      break;
    case REQUEST_OTP_RESET:
      try {
        yield initRequest();
        const result: Record<string, any> = yield call(
          api.requestOTPReset,
          payload
        );
        // console.log(result); // debugger;
        if (result?.success) {
          yield put(setIsAuthenticated(false));
          const obj: TAuthStatus = {
            success: true,
            error: "OTP Sent",
            isError: false,
          };
          yield put(setAuthStatus(obj));
        }
      } catch (error) {
        yield handleError(error);
        yield put(setToggleErrorModal(true));
      } finally {
        yield put(setIsAuthenticated(false));
      }
      break;
    case REQUEST_OTP:
      try {
        yield initRequest();
        const result: Record<string, any> = yield call(
          api.requestOTPReset,
          payload
        );
        // console.log(result); // debugger;
        if (result?.success) {
          yield put(setIsAuthenticated(false));
          const obj: TAuthStatus = {
            success: true,
            error: "OTP Sent",
            isError: false,
          };
          yield put(setAuthStatus(obj));
        }
      } catch (error) {
        yield handleError(error);
        yield put(setToggleErrorModal(true));
      } finally {
        yield put(setIsAuthenticated(false));
      }
      break;
    case VERIFY_OTP:
      try {
        yield initRequest();
        const result: Record<string, any> = yield call(api.verifyOTP, payload);
        // console.log(result); // debugger;
        if (result?.success) {
          yield put(setIsAuthenticated(false));
          const obj: TAuthStatus = {
            success: true,
            error: "OTP successfully verified",
            isError: false,
          };
          yield put(setAuthStatus(obj));
        } else {
          yield put(setIsAuthenticated(false));
          const obj: TAuthStatus = {
            success: false,
            error: result?.error,
            isError: true,
          };
          yield put(setAuthStatus(obj));
        }
      } catch (error) {
        yield handleError(error);
        yield put(setToggleErrorModal(true));
      } finally {
        yield put(setIsAuthenticated(false));
      }
      break;
    default:
      yield console.log("Auth default");
      break;
  }
}

export default authSaga;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface INotifyState {
  type: string;
  payload?: any;
}

const initialState: INotifyState = {
  type: "",
  payload: null,
};

const notifySlice = createSlice({
  name: "notify",
  initialState,
  reducers: {
    setNotify: (state, action: PayloadAction<INotifyState>) => {
      state.type = action.payload.type;
      state.payload = action.payload.payload;
    },
  },
});

export const { setNotify } = notifySlice.actions;
export default notifySlice.reducer;

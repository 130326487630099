import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import appSlice from "./app.slice";
import authSlice from "./auth.slice";
import cartSlice from "./cart.slice";
import notifySlice from "./notify.slice";
import orderSlice from "./order.slice";
import productSlice from "./product.slice";
import walletSlice from "./wallet.slice";

// products reduce persist config
const persistProductConfig = {
  key: "@tendo-web-ng-products",
  storage,
  blacklist: [
    "feedProducts",
    "exploreProducts",
    "categoryProducts",
    "searchProduct",
    "promoProducts",
    "sharedProductPrice",
    "fetchingProductsStatus",
    "isProductFetching",
  ],
};

// app slice persist config
const persistAppConfig = {
  key: "@tendo-web-ng-app",
  storage,
  blacklist: [
    "totalRecord",
    "promoName",
    "categoryName",
    "productName",
    "isFetching",
    "previousPage",
  ],
};

// orders slice persist config
const persistOrdersConfig = {
  key: "@tendo-web-ng-orders",
  storage,
  blacklist: ["orders", "sharedProducts", "navigate"],
};

const persistAuthConfig = {
  key: "@tendo-web-ng-auth",
  storage,
  blacklist: [
    "fromRoute",
    "authLoader",
    "user",
    "isAuthenticated",
    "authStatus",
    "authLoading",
  ],
};

const rootSlice = combineReducers({
  app: persistReducer(persistAppConfig, appSlice),
  products: persistReducer(persistProductConfig, productSlice),
  auth: persistReducer(persistAuthConfig, authSlice),
  orders: persistReducer(persistOrdersConfig, orderSlice),
  wallet: walletSlice,
  cart: cartSlice,
  notify: notifySlice,
});

export default rootSlice;

import {
  DOWNLOAD_PRODUCT_IMAGE,
  GET_CATEGORIES,
  GET_CATEGORY_PRODUCTS,
  GET_EXPLORE_PRODUCTS,
  GET_HOME_PRODUCTS,
  GET_PRODUCT,
  GET_PROMOTIONS,
  GET_PROMO_PRODUCTS,
  PRODUCT_FETCH_ERROR,
  SEARCH_PRODUCTS,
  SET_PRODUCTS,
  SHARE_PRODUCT,
} from "../../utils/constants";

export const getProducts = (payload: Record<string, any>) => ({
  type: GET_HOME_PRODUCTS,
  payload,
});

export const getExploreProducts = (payload: Record<string, any>) => ({
  type: GET_EXPLORE_PRODUCTS,
  payload,
});

export const getCategories = (payload?: Record<string, any>) => ({
  type: GET_CATEGORIES,
  payload,
});

export const getPromotions = (payload?: Record<string, any>) => ({
  type: GET_PROMOTIONS,
  payload,
});

export const setProduct = (payload: Record<string, any>) => ({
  type: SET_PRODUCTS,
  payload,
});

export const getProduct = (payload: string) => ({
  type: GET_PRODUCT,
  payload,
});

export const productFetchError = (payload: Record<string, any>) => ({
  type: PRODUCT_FETCH_ERROR,
  payload,
});

export const getCategoryProducts = (payload: Record<string, any>) => ({
  type: GET_CATEGORY_PRODUCTS,
  payload,
});

export const getPromoProducts = (payload: Record<string, any>) => ({
  type: GET_PROMO_PRODUCTS,
  payload,
});

export const shareProduct = (payload: Record<string, any>) => ({
  type: SHARE_PRODUCT,
  payload,
});

export const downloadProductMedia = (payload: string) => ({
  type: DOWNLOAD_PRODUCT_IMAGE,
  payload,
});

export const searchProducts = (payload: string) => ({
  type: SEARCH_PRODUCTS,
  payload,
});

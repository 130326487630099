import { ArrowLeftIcon } from "@heroicons/react/solid";
import { notification } from "antd";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import PhoneInput from "react-phone-input-2";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  setUpdateUserDetails,
  resetOTPRequest,
  verifyOTP,
} from "../redux/actions/authActions";
import { useAppSelector } from "../redux/hook";
import { setToggleRegister, setToggleLogin } from "../redux/slice/app.slice";
import { selectAuthStatus, setAuthStatus } from "../redux/slice/auth.slice";
import { formatNigeriaPhoneNumber } from "../utils/lib";

type Props = {};

const ResetPassword = (props: Props) => {
  const dispatch = useDispatch();
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [otpCode, setOTPCode] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [showPasswordInput, setShowPasswordInput] = useState(false);
  const [showResetPasswordInput, setShowResetPasswordInput] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [resetPasswordNonMatch, setResetPasswordNonMatch] = useState(false);
  const [useEmail, setUseEmail] = useState(false);
  const [isOTPValid, setIsOTPValid] = useState(false);
  const [isReset, setIsReset] = useState(true);
  const [showEmailPhoneInput, setShowEmailPhoneInput] = useState(true);
  const { isAuthenticated } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const location = useLocation();

  const init = useRef({
    navigate,
  });

  const authStatus = useAppSelector(selectAuthStatus);
  const urlState = useMemo(
    () => new URLSearchParams(location.search),
    [location]
  );

  const type = useMemo(
    () => (urlState.get("type") ? urlState.get("type") : ""),
    [urlState]
  );
  const typeValue = useMemo(
    () => (urlState.get("value") ? urlState.get("value") : ""),
    [urlState]
  );
  const addPassword = useMemo(
    () => (urlState.get("add_password") ? urlState.get("add_password") : false),
    [urlState]
  );
  const otpSent = useMemo(
    () =>
      authStatus?.success &&
      !authStatus.isError &&
      authStatus.error.toLowerCase() === "otp sent",
    [authStatus?.error, authStatus?.isError, authStatus?.success]
  );
  const otpVerify = useMemo(
    () =>
      authStatus?.success &&
      !authStatus.isError &&
      authStatus.error.toLowerCase() === "otp successfully verified",
    [authStatus?.error, authStatus?.isError, authStatus?.success]
  );
  const invalidOTP = useMemo(
    () =>
      !authStatus?.success &&
      authStatus?.isError &&
      authStatus.error.toLowerCase() === "invalid otp code",
    [authStatus?.error, authStatus?.isError, authStatus?.success]
  );
  const passwordUpdated = useMemo(
    () =>
      authStatus?.success &&
      !authStatus.isError &&
      authStatus.error.toLowerCase() === "update successful",
    [authStatus?.error, authStatus?.isError, authStatus?.success]
  );

  useEffect(() => {
    if (type === "phone") {
      setPhone(typeValue as string);
    } else if (type === "email") {
      setEmail(typeValue as string);
      setUseEmail(true);
    }

    if (addPassword === "true") {
      setIsReset(false);
    }
  }, [addPassword, type, typeValue]);

  useEffect(() => {
    const { navigate } = init.current;
    if (otpSent) {
      notification.success({
        message: "OTP Sent",
        description: "OTP has been sent to your phone number and/or mail",
        duration: 3,
      });
      setShowEmailPhoneInput(false);
      setShowPasswordInput(true);
      setIsOTPValid(false);
    }
    if (otpVerify) {
      setShowEmailPhoneInput(false);
      setShowPasswordInput(false);
      setShowResetPasswordInput(true);
      setIsOTPValid(false);
    }

    if (invalidOTP) {
      setIsOTPValid(true);
    }

    if (passwordUpdated) {
      setIsOTPValid(false);
      notification.success({
        message: "Password Updated",
        description: "Password has been updated successfully, please login",
        duration: 5,
      });
      navigate("/auth/");
    }
  }, [otpSent, otpVerify, passwordUpdated, invalidOTP]);

  const onLoginSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (showResetPasswordInput) {
      if (newPassword !== confirmNewPassword) {
        return setResetPasswordNonMatch(true);
      }
      dispatch(
        setUpdateUserDetails({
          user: { password: newPassword, phone, email },
          action: "update/password",
        })
      );
    } else if (showPasswordInput) {
      // verify otp code
      dispatch(verifyOTP({ code: otpCode, phone, email }));
    } else {
      dispatch(resetOTPRequest({ phone, email }));
    }
  };

  const resendCode = () => {
    dispatch(resetOTPRequest({ phone, email }));
  };

  return (
    <form onSubmit={onLoginSubmit} className="w-full">
      <div className="text-left mb-9">
        <h1 className="text-3xl">
          {isReset ? "Reset password 🥴" : "Set password 🔨"}
        </h1>
        {isReset ? (
          <p className="text-sm font-semibold text-gray-400 mt-3">
            Remembered password?{" "}
            <span
              className="ml-1 text-blue-500 cursor-pointer"
              onClick={() => {
                navigate(-1);
              }}
            >
              Login
            </span>
          </p>
        ) : (
          <p className="text-sm font-semibold text-gray-400 mt-3">
            Please update your account with a password 🔑
          </p>
        )}
      </div>
      {showEmailPhoneInput && (
        <div className="mb-2">
          <div className="flex justify-between items-center">
            <label htmlFor="phone" className="block text-sm font-medium">
              {`Your ${useEmail ? "email" : "phone number"}`}
            </label>
            {useEmail ? (
              <span
                className="block text-sm font-medium text-blue-500 mt-1 cursor-pointer"
                onClick={() => setUseEmail(!useEmail)}
              >
                use phone
              </span>
            ) : (
              <span
                className="block text-sm font-medium text-blue-500 mt-1 cursor-pointer"
                onClick={() => setUseEmail(true)}
              >
                use email
              </span>
            )}
          </div>
          <div className="mt-2 relative rounded-md shadow-sm">
            {useEmail ? (
              <input
                type="email"
                id="password"
                className="focus:ring-sokoBlue focus:border-sokoBlue block w-full pl-7 pr-12 py-4 sm:text-sm border-gray-300 border-2 rounded-md"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                placeholder="example@mail.com"
              />
            ) : (
              <PhoneInput
                country={"ng"}
                enableSearch={true}
                onlyCountries={["ng"]}
                countryCodeEditable={false}
                inputStyle={{ width: "100%" }}
                inputClass="focus:ring-sokoBlue focus:border-sokoBlue sm:text-sm border-gray-300 py-4"
                placeholder="+234 80xxxxxx"
                value={phone}
                onChange={(value) => {
                  formatNigeriaPhoneNumber({
                    phone: value,
                    cb: setPhone,
                    errorCb: setPhoneError,
                  });
                }}
              />
            )}
          </div>
          {phoneError && (
            <span className="text-red-500 mt-1 text-xs font-semibold">
              Invalid phone number
            </span>
          )}
        </div>
      )}

      {showPasswordInput && !showResetPasswordInput && (
        <div className="mb-5">
          <div className="mb-6">
            <span className="text-sm">
              We have sent you a code to phone number as SMS and/or email, enter
              it below to continue.
            </span>
          </div>
          <label htmlFor="email" className="block text-sm font-medium">
            Enter the 6 digit code you received here
          </label>
          <div className="mt-2 relative rounded-md shadow-sm">
            <input
              type="text"
              id="password"
              className="focus:ring-sokoBlue focus:border-sokoBlue block w-full pl-7 pr-12 py-4 sm:text-sm border-gray-300 border-2 rounded-md"
              onChange={(e) => setOTPCode(e.target.value)}
              maxLength={6}
              value={otpCode}
              placeholder="xxxx"
              inputMode="numeric"
            />
          </div>
          <div className="flex justify-between items-center py-2">
            {isOTPValid && (
              <span className="text-red-500 text-sm text-font-medium">
                Invalid OTP Code
              </span>
            )}
            <span
              className="text-center text-sm font-semibold text-blue-500 flex  justify-end cursor-pointer"
              onClick={resendCode}
            >
              Resend code
            </span>
          </div>
        </div>
      )}

      {showResetPasswordInput && (
        <div className="mb-5">
          <div className="mb-2">
            <label htmlFor="email" className="block text-sm font-medium">
              New password
            </label>
            <div className="mt-2 relative rounded-md shadow-sm">
              <input
                type="password"
                id="new-password"
                className="focus:ring-sokoBlue focus:border-sokoBlue block w-full pl-7 pr-12 py-4 sm:text-sm border-gray-300 border-2 rounded-md"
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </div>
          </div>
          <div className="mb-2">
            <label htmlFor="email" className="block text-sm font-medium">
              Confirm new password
            </label>
            <div className="mt-2 relative rounded-md shadow-sm">
              <input
                type="password"
                id="confirm-new-password"
                className="focus:ring-sokoBlue focus:border-sokoBlue block w-full pl-7 pr-12 py-4 sm:text-sm border-gray-300 border-2 rounded-md"
                onChange={(e) => setConfirmNewPassword(e.target.value)}
              />
            </div>
          </div>
          {resetPasswordNonMatch && (
            <div className="text-red-500 mt-1 text-xs font-semibold">
              Passwords do not match.
            </div>
          )}
        </div>
      )}

      <div className="flex flex-col">
        <div className="my-5 w-full">
          <button
            type="submit"
            className={`w-full flex justify-center py-4 px-4 border border-transparent text-base font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 text-white ${
              isAuthenticated ? "cursor-not-allowed bg-gray-300" : "bg-blue-500"
            }`}
            disabled={isAuthenticated}
          >
            {isAuthenticated && (
              <AiOutlineLoading3Quarters className="h-6 w-6 animate-spin text-blue-400 mr-2" />
            )}{" "}
            {showResetPasswordInput
              ? "Reset Password"
              : !showPasswordInput && !showResetPasswordInput
              ? "Next"
              : showPasswordInput
              ? "Verify"
              : "Done"}
          </button>
        </div>
        <span
          className="text-center text-sm font-semibold text-blue-500 flex items-center justify-center cursor-pointer"
          onClick={() => {
            dispatch(setAuthStatus(null));
            dispatch(setToggleLogin(true));
            dispatch(setToggleRegister(false));
            navigate("/auth/");
          }}
        >
          <ArrowLeftIcon className="h-3 w-3 text-bray-400 mr-2" /> Back
        </span>
      </div>
    </form>
  );
};

export default ResetPassword;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

export type TAuthStatus = {
  success: boolean;
  error: string;
  isError: boolean;
} | null;
interface AuthState {
  isAuthenticated: boolean;
  user: Record<string, any> | null;
  authStatus: TAuthStatus;
  authLoading: boolean;
  fromRoute: string | null;
}

const initialState: AuthState = {
  isAuthenticated: false,
  user: null,
  authStatus: null,
  authLoading: false,
  fromRoute: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setIsAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
    setUser: (state, action: PayloadAction<Record<string, any> | null>) => {
      state.user = action.payload;
    },
    setAuthStatus: (state, action: PayloadAction<TAuthStatus>) => {
      state.authStatus = action.payload;
    },
    setAuthLoading: (state, action: PayloadAction<boolean>) => {
      state.authLoading = action.payload;
    },
    setFromRoute: (state, action: PayloadAction<string | null>) => {
      state.fromRoute = action.payload;
    },
  },
});

export const {
  setUser,
  setIsAuthenticated,
  setAuthStatus,
  setAuthLoading,
  setFromRoute,
} = authSlice.actions;

export const selectCurrentUser = (state: RootState) => state.auth.user;
export const selectAuthStatus = (state: RootState) => state.auth.authStatus;

export default authSlice.reducer;

import React, { useEffect } from "react";
import { AuthLoader } from "./components/common/AuthLoader";
import AppLayout from "./layouts/AppLayout";
import { useAppSelector } from "./redux/hook";

function App() {
  const { authLoading } = useAppSelector((state) => state.auth);
  useEffect(() => {
    window.location.href = "https://onelink.to/dukesell";
  }, []);

  if (authLoading) return <AuthLoader />;
  return <AppLayout />;
}

export default App;

import React from "react";
import NavContainer from "./NavContainer";
import { BsFillGridFill, BsFillPersonFill } from "react-icons/bs";
import { AiFillHdd } from "react-icons/ai";
import NavItem from "./NavItem";
import { CollectionIcon, HomeIcon } from "@heroicons/react/solid";

interface Props {}

const BottomNavigation = (props: Props) => {
  return (
    <NavContainer>
      <NavItem
        icon={<HomeIcon className="h-6 w-5" />}
        title={"Home"}
        index={0}
        path={"/"}
      />
      <NavItem
        icon={<AiFillHdd className="h-6 w-5" />}
        title={"Categories"}
        index={1}
        path={"/categories"}
      />
      <NavItem
        icon={<BsFillGridFill className="h-6 w-5" />}
        title={"Explore"}
        index={2}
        path={"/explore"}
      />
      <NavItem
        icon={<CollectionIcon className="h-6 w-6" />}
        title={"Shared"}
        index={3}
        path={"/promotions"}
      />
      <NavItem
        icon={<BsFillPersonFill className="h-6 w-5" />}
        title={"Account"}
        index={4}
        path={"/accounts"}
      />
    </NavContainer>
  );
};

export default BottomNavigation;

import { call, put } from "redux-saga/effects";
import api from "../../services/api.service";
import {
  DELIVERY_DATA,
  GET_ALL_USER_ORDERS,
  GET_ORDER_DETAILS,
  GET_USER_SHARED_PRODUCTS,
  ORDERING,
  ORDER_PRODUCT,
} from "../../utils/constants";
import { setTotalRecord } from "../slice/app.slice";
import {
  setDeliveryLocations,
  setIsOrdering,
  setNavigate,
  setOrderingStatus,
  setOrderProduct,
  setOrders,
  setProductOrdered,
  setSharedProducts,
} from "../slice/order.slice";

function* handleError(error?: any) {
  const obj: Record<string, any> = {};
  obj.error = error.message || "Something went wrong";
  obj.success = false;
  obj.isError = true;

  yield put(setOrderingStatus(obj));
  yield put(setIsOrdering(false));
}

function* initRequest() {
  yield put(setIsOrdering(true));
  yield put(setOrderingStatus(null));
}

function* requestDone() {
  yield put(setIsOrdering(false));
  yield put(setOrderingStatus(null));
}

function* orderSaga({ type, payload }: { type: string; payload: any }) {
  switch (type) {
    case ORDER_PRODUCT:
      yield put(setOrderProduct(payload));
      break;
    case DELIVERY_DATA:
      const sheet = "prod-delivery-prices";

      try {
        const data: Record<string, any> = yield call(api.getSheetData, sheet);

        if (data.success === true) {
          yield put(setDeliveryLocations(data.data));
        }
      } catch (error) {
        console.log(error);
      }
      break;
    case ORDERING:
      try {
        yield initRequest();
        const order: Record<string, any> = yield call(
          api.orderingProduct,
          payload
        );
        if (order?.newAppOrder.sku) {
          yield put(setNavigate(true));
          yield requestDone();
        }
      } catch (error) {
        console.log(error);
        yield handleError(error);
      }
      break;
    case GET_ALL_USER_ORDERS:
      yield initRequest();
      try {
        const sheet = "New App Orders";
        const orders: Record<string, any> = yield call(
          api.getPagedSheetData,
          sheet,
          payload
        );
        // console.log(orders);

        if (orders.success === true) {
          yield put(
            setOrders(
              orders.data.sort((a: { id: number }, b: { id: number }) =>
                b.id > a.id ? 1 : -1
              )
            )
          );
          yield put(setTotalRecord(orders.totalRecord));
          yield requestDone();
        }
      } catch (error) {
        console.log(error);
        yield handleError(error);
      }
      break;
    case GET_ORDER_DETAILS:
      try {
        yield initRequest();
        const order: Record<string, any> = yield call(
          api.getOrderById,
          payload
        );
        // console.log(order);

        const orderSheet = "evans-home";
        const orderProduct: Record<string, any> = yield call(
          api.getSheetData,
          orderSheet,
          { "filter[skus]": order.data.newAppOrders[0].sku }
        );
        // console.log(order, orderProduct);
        yield put(
          setProductOrdered({
            order: order.data.newAppOrders[0],
            product: orderProduct.data[0],
          })
        );
        yield requestDone();
      } catch (error) {
        console.log(error);
        yield handleError(error);
      }
      break;
    case GET_USER_SHARED_PRODUCTS:
      yield initRequest();
      try {
        const sheet = "Shared Products";
        const products: Record<string, any> = yield call(
          api.getPagedSheetData,
          sheet,
          payload
        );
        // console.log(products);

        if (products.success === true) {
          yield put(
            setSharedProducts(
              products.data.sort((a: { id: number }, b: { id: number }) =>
                b.id > a.id ? 1 : -1
              )
            )
          );
          yield put(setTotalRecord(products.totalRecord));
          yield requestDone();
        }
      } catch (error) {
        console.log(error);
        yield handleError(error);
      }
      break;
    default:
      yield console.log("Order default");
      break;
  }
}

export default orderSaga;

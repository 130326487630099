import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface WalletState {
  totalOrders: number;
  successfulOrders: number;
  failedOrders: number;
  totalRevenue: number;
  totalCashOut: number;
  availableBalance: number;
  transactions: Record<string, any>[];
}

const initialState: WalletState = {
  totalOrders: 0,
  successfulOrders: 0,
  failedOrders: 0,
  totalRevenue: 0,
  totalCashOut: 0,
  availableBalance: 0,
  transactions: [],
};

const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    setTotalOrders: (state, action: PayloadAction<number>) => {
      state.totalOrders = action.payload;
    },
    setSuccessfulOrders: (state, action: PayloadAction<number>) => {
      state.successfulOrders = action.payload;
    },
    setFailedOrders: (state, action: PayloadAction<number>) => {
      state.failedOrders = action.payload;
    },
    setTotalRevenue: (state, action: PayloadAction<number>) => {
      state.totalRevenue = action.payload;
    },
    setTotalCashOut: (state, action: PayloadAction<number>) => {
      state.totalCashOut = action.payload;
    },
    setAvailableBalance: (state, action: PayloadAction<number>) => {
      state.availableBalance = action.payload;
    },
    setTransaction: (state, action: PayloadAction<Record<string, any>[]>) => {
      state.transactions = [...action.payload];
    },
  },
});

export const {
  setTotalOrders,
  setSuccessfulOrders,
  setFailedOrders,
  setTotalRevenue,
  setTotalCashOut,
  setAvailableBalance,
  setTransaction,
} = walletSlice.actions;

export default walletSlice.reducer;

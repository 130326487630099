import {
  AUTHENTICATE_USER,
  LOGIN_USER,
  LOGOUT,
  REGISTER_USER,
  REQUEST_OTP,
  REQUEST_OTP_RESET,
  UPDATE_USER_PAYMENT,
  UPDATE_USER_PROFILE,
  VERIFY_OTP,
} from "../../utils/constants";
import { IUser } from "../../utils/types";

export const authenticateUser = (payload: string) => ({
  type: AUTHENTICATE_USER,
  payload,
});

export const loginUser = (payload: {
  phone?: string;
  password?: string;
  email?: string;
}) => ({
  type: LOGIN_USER,
  payload,
});

export const registerUser = (payload: Record<string, any>) => ({
  type: REGISTER_USER,
  payload,
});

export const logoutUser = (payload: boolean) => ({
  type: LOGOUT,
});

export const setUpdateUserDetails = (payload: {
  action: string;
  user: Partial<IUser>;
}) => ({
  type: UPDATE_USER_PROFILE,
  payload,
});

export const setPaymentMethod = (payload: {
  action: string;
  user: Partial<IUser>;
}) => ({
  type: UPDATE_USER_PAYMENT,
  payload,
});

export const resetOTPRequest = (payload: {
  phone?: string;
  email?: string;
}) => ({
  type: REQUEST_OTP_RESET,
  payload,
});

export const OTPRequest = (payload: { phone?: string; email?: string }) => ({
  type: REQUEST_OTP,
  payload,
});

export const verifyOTP = (payload: {
  phone?: string;
  email?: string;
  code: string;
}) => ({
  type: VERIFY_OTP,
  payload,
});

import React from "react";
import { Link } from "react-router-dom";
import { setProduct } from "../redux/actions/productActions";
import { useAppDispatch } from "../redux/hook";

interface Props {
  item: Record<string, any>;
}

const SearchItem = ({ item }: Props) => {
  const dispatch = useAppDispatch();

  const selectProduct = () => {
    dispatch(setProduct(item));
  };

  return (
    <Link
      to={`/product/${item?.product?.replace("/", "$").replace("%", "")}`}
      onClick={selectProduct}
    >
      <div className="mx-2 flex items-center">
        <div className="flex-shrink-0 pt-0.5">
          <img
            className="h-12 w-12 rounded-md"
            src={item?.newImageServerLink}
            alt=""
          />
        </div>
        <div className="ml-3 flex flex-col w-full">
          <span className="text-sm font-medium text-gray-900">
            {item?.product}
          </span>
          <span className="mt-1 font-semibold text-sm text-gray-500">
            {item?.wholesale}
          </span>
        </div>
      </div>
    </Link>
  );
};

export default SearchItem;

import { Select } from "antd";
import _ from "lodash";
import React, { useEffect, useMemo } from "react";
import { searchProducts } from "../redux/actions/productActions";
import { useAppDispatch, useAppSelector } from "../redux/hook";
import { setSearchProduct } from "../redux/slice/product.slice";
import SearchItem from "./SearchItem";

interface Props {
  data: Record<string, any>[];
}

const Search = ({ data }: Props) => {
  const { Option } = Select;
  const dispatch = useAppDispatch();
  const { searchProduct, isProductFetching } = useAppSelector(
    (state) => state.products
  );

  useEffect(() => {
    dispatch(setSearchProduct(data));
  }, [data, dispatch]);

  const options = useMemo(() => {
    return searchProduct.map((item) => (
      <Option key={item.skus} value={item.product}>
        <SearchItem item={item} />
      </Option>
    ));
  }, [Option, searchProduct]);

  function onChange(value: any) {
    console.log(`selected ${value}`);
  }

  function onSearch(val: string) {
    console.log("search:", val);
    if (val.length >= 3) {
      if (searchProduct.length !== 0) {
        const filteredData = searchProduct.filter((item) => {
          return (
            item.product.toLowerCase().includes(val.toLowerCase()) ||
            item.skus.toLowerCase().includes(val.toLowerCase()) ||
            item.cleanDescriptionWithSkus
              .toLowerCase()
              .includes(val.toLowerCase())
          );
        });
        if (filteredData.length !== 0) {
          const uniqueData = _.uniqBy(filteredData, "skus");
          dispatch(setSearchProduct(uniqueData));
        }

        dispatch(searchProducts(val));
      } else {
        dispatch(setSearchProduct(data));
      }
    } else {
      dispatch(setSearchProduct(data));
    }
  }

  return (
    <Select
      showSearch
      placeholder="Search product, sku or brand"
      optionFilterProp="children"
      onChange={onChange}
      onSearch={onSearch}
      filterOption={false}
      className="w-full search-bar"
      loading={isProductFetching}
    >
      {options}
    </Select>
  );
};

export default Search;

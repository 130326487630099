import React from "react";
import { useMediaQuery } from "react-responsive";
import { Link, useNavigate } from "react-router-dom";
import YouTube, { YouTubeProps } from "react-youtube";

type Props = {};

const Onboarding = (props: Props) => {
  const navigate = useNavigate();

  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });
  const onPlayerReady: YouTubeProps["onReady"] = (event) => {
    event.target.playVideo();
  };

  const opts: YouTubeProps["opts"] = {
    height: "390",
    width: isTabletOrMobile ? "400" : "640",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };

  const goHome = () => {
    navigate("/");
  };

  return (
    <div className="flex flex-col items-center px-4">
      <div className="text-center mb-9">
        <h1 className="text-xl">Learn how to get started 🚀 on Tendo</h1>
      </div>
      <YouTube
        videoId="AnnOotmLLYw"
        opts={opts}
        onReady={onPlayerReady}
        onEnd={goHome}
      />
      <div className="text-center flex flex-col space-y-4 mt-6">
        <span className="text-sm font-semibold">
          Thanks 👍🏾, I'll watch <Link to="/">maybe later</Link>
        </span>
        <span className="text-sm font-semibold">
          Want to learn more?{" "}
          <Link
            to={{
              pathname: "/accounts/learn",
              search: "?from=onboarding",
            }}
          >
            watch our masterclass
          </Link>
        </span>
      </div>
    </div>
  );
};

export default Onboarding;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ProductState {
  feedProducts: Record<string, any>[];
  exploreProducts: Record<string, any>[];
  categoryProducts: Record<string, any>[];
  promoProducts: Record<string, any>[];
  selectedProduct: Record<string, any> | null;
  productFetchError: Record<string, any> | null;
  category: Record<string, any>[];
  promotions: Record<string, any>[];
  isCategoryProductsEmpty: boolean;
  isPromoProductsEmpty: boolean;
  sharedProductPrice: number;
  fetchingProductsStatus: Record<string, any> | null;
  isProductFetching: boolean;
  searchProduct: Record<string, any>[];
}

const initialState: ProductState = {
  feedProducts: [],
  exploreProducts: [],
  categoryProducts: [],
  promoProducts: [],
  selectedProduct: null,
  productFetchError: null,
  category: [],
  promotions: [],
  isCategoryProductsEmpty: false,
  isPromoProductsEmpty: false,
  sharedProductPrice: 0,
  fetchingProductsStatus: null,
  isProductFetching: false,
  searchProduct: [],
};

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    setFeedProducts: (state, action: PayloadAction<Record<string, any>[]>) => {
      state.feedProducts = [...state.feedProducts, ...action.payload];
    },
    setExploreProducts: (
      state,
      action: PayloadAction<Record<string, any>[]>
    ) => {
      state.exploreProducts = [...state.exploreProducts, ...action.payload];
    },
    setCategoryProducts: (
      state,
      action: PayloadAction<Record<string, any>[] | null>
    ) => {
      state.categoryProducts =
        action.payload === null
          ? []
          : [...state.categoryProducts, ...action.payload];
    },
    setPromoProducts: (
      state,
      action: PayloadAction<Record<string, any>[] | null>
    ) => {
      state.promoProducts =
        action.payload === null
          ? []
          : [...state.promoProducts, ...action.payload];
    },
    setSelectedProduct: (
      state,
      action: PayloadAction<Record<string, any> | null>
    ) => {
      state.selectedProduct = action.payload;
    },

    setProductFetchError: (
      state,
      action: PayloadAction<Record<string, any> | null>
    ) => {
      state.productFetchError = action.payload;
    },
    setCategory: (state, action: PayloadAction<Record<string, any>[]>) => {
      state.category = [...action.payload];
    },
    setPromotions: (state, action: PayloadAction<Record<string, any>[]>) => {
      state.promotions = [...action.payload];
    },
    setIsCategoryProductsEmpty: (state, action: PayloadAction<boolean>) => {
      state.isCategoryProductsEmpty = action.payload;
    },
    setIsPromoProductsEmpty: (state, action: PayloadAction<boolean>) => {
      state.isPromoProductsEmpty = action.payload;
    },
    setSharedProductPrice: (state, action: PayloadAction<number>) => {
      state.sharedProductPrice = action.payload;
    },
    setFetchingProductsStatus: (
      state,
      action: PayloadAction<Record<string, any> | null>
    ) => {
      state.fetchingProductsStatus = action.payload;
    },
    setIsProductFetching: (state, action: PayloadAction<boolean>) => {
      state.isProductFetching = action.payload;
    },
    setSearchProduct: (state, action: PayloadAction<Record<string, any>[]>) => {
      state.searchProduct = [...action.payload];
    },
  },
});

export const {
  setCategoryProducts,
  setExploreProducts,
  setFeedProducts,
  setPromoProducts,
  setSelectedProduct,
  setProductFetchError,
  setCategory,
  setPromotions,
  setIsCategoryProductsEmpty,
  setIsPromoProductsEmpty,
  setSharedProductPrice,
  setFetchingProductsStatus,
  setIsProductFetching,
  setSearchProduct,
} = productSlice.actions;

export default productSlice.reducer;

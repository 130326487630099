import React, { useEffect, useMemo, useRef, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { useDispatch } from "react-redux";
import { setToggleLogin, setToggleRegister } from "../redux/actions/appActions";
import { loginUser } from "../redux/actions/authActions";
import { useAppSelector } from "../redux/hook";
import { formatNigeriaPhoneNumber } from "../utils/lib";
import "react-phone-input-2/lib/bootstrap.css";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { selectAuthStatus } from "../redux/slice/auth.slice";
import { useNavigate } from "react-router-dom";
import { notification } from "antd";
import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline";

interface Props {}

const LoginForm = (props: Props) => {
  const dispatch = useDispatch();
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [showPasswordInput, setShowPasswordInput] = useState(false);
  const [useEmail, setUseEmail] = useState(false);
  const [hidePassword, setHidePassword] = useState(false);
  const { isAuthenticated } = useAppSelector((state) => state.auth);
  const { previousPage, toggleLogin } = useAppSelector((state) => state.app);
  const navigate = useNavigate();

  const init = useRef({
    navigate,
  });

  const authStatus = useAppSelector(selectAuthStatus);

  const userHasPassword = useMemo(
    () =>
      authStatus &&
      authStatus.error &&
      authStatus.error.toLowerCase() === "enter password",
    [authStatus]
  );
  const userHasNoPassword = useMemo(
    () =>
      authStatus &&
      authStatus.error &&
      authStatus.error.toLowerCase() === "no password",
    [authStatus]
  );
  const isError = useMemo(
    () => authStatus && authStatus.isError && !authStatus.success,
    [authStatus]
  );

  useEffect(() => {
    if (
      isError &&
      !["no password", "enter password"].includes(
        authStatus?.error?.toLowerCase() as string
      )
    ) {
      notification.error({
        message: "Error occurred",
        description: authStatus?.error,
      });
    }
  }, [authStatus?.error, isError]);

  useEffect(() => {
    if (userHasPassword) {
      setShowPasswordInput(true);
    }
  }, [userHasPassword]);

  useEffect(() => {
    const { navigate } = init.current;
    if (userHasNoPassword) {
      notification.info({
        message: "Set Password",
        description: "You have not set a password yet, please set a password",
        duration: 5,
      });
      navigate({
        pathname: "/auth/reset-password",
        search: `?add_password=${true}&type=${
          useEmail ? "email" : "phone"
        }&value=${useEmail ? email : phone}`,
      });
    }
  }, [email, phone, useEmail, userHasNoPassword]);

  useEffect(() => {
    const { navigate } = init.current;
    if (!toggleLogin) {
      if (previousPage) {
        navigate(previousPage);
      } else {
        navigate("/");
      }
    }
  }, [previousPage, toggleLogin]);

  const showPassword = () => {
    setHidePassword(!hidePassword);
  };

  const onLoginSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    dispatch(loginUser({ phone, password, email }));
  };

  return (
    <form onSubmit={onLoginSubmit} className="w-full">
      <div className="text-left mb-9">
        <h1 className="text-3xl">Welcome back ✌🏽</h1>
        <p className="text-sm font-semibold text-gray-400 mt-3">
          Not a reseller yet?{" "}
          <span
            className="ml-1 text-blue-500 cursor-pointer"
            onClick={() => {
              dispatch(setToggleRegister(true));
              dispatch(setToggleLogin(false));
            }}
          >
            Create account here
          </span>
        </p>
      </div>
      <div className="mb-2">
        <div className="flex justify-between items-center">
          <label htmlFor="phone" className="block text-sm font-medium">
            {`Your ${useEmail ? "email" : "phone number"}`}
          </label>
          {useEmail ? (
            <span
              className="block text-sm font-medium text-blue-500 mt-1 cursor-pointer"
              onClick={() => setUseEmail(!useEmail)}
            >
              use phone
            </span>
          ) : (
            <span
              className="block text-sm font-medium text-blue-500 mt-1 cursor-pointer"
              onClick={() => setUseEmail(true)}
            >
              use email
            </span>
          )}
        </div>
        <div className="mt-2 relative rounded-md shadow-sm">
          {useEmail ? (
            <input
              type="email"
              id="password"
              className="focus:ring-sokoBlue focus:border-sokoBlue block w-full pl-7 pr-12 py-4 sm:text-sm border-gray-300 border-2 rounded-md"
              onChange={(e) => setEmail(e.target.value)}
              placeholder="example@mail.com"
            />
          ) : (
            <PhoneInput
              country={"ng"}
              enableSearch={true}
              onlyCountries={["ng"]}
              countryCodeEditable={false}
              inputStyle={{ width: "100%" }}
              inputClass="focus:ring-sokoBlue focus:border-sokoBlue sm:text-sm border-gray-300 py-4"
              placeholder="+234 80xxxxxx"
              value={phone}
              onChange={(value) => {
                formatNigeriaPhoneNumber({
                  phone: value,
                  cb: setPhone,
                  errorCb: setPhoneError,
                });
              }}
            />
          )}
        </div>
        {phoneError && (
          <span className="text-red-500 mt-1 text-xs font-semibold">
            Invalid phone number
          </span>
        )}
      </div>
      {showPasswordInput && (
        <div className="mb-5">
          <label htmlFor="email" className="block text-sm font-medium">
            Password
          </label>
          <div className="mt-2 relative rounded-md shadow-sm">
            <input
              type={hidePassword ? "text" : "password"}
              id="password"
              className="focus:ring-sokoBlue focus:border-sokoBlue block w-full pl-7 pr-12 py-4 sm:text-sm border-gray-300 border-2 rounded-md"
              onChange={(e) => setPassword(e.target.value)}
            />
            <div
              className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
              onClick={showPassword}
            >
              {hidePassword ? (
                <EyeOffIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              ) : (
                <EyeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              )}
            </div>
          </div>
          <span
            className="text-blue-500 mt-1 text-xs font-semibold cursor-pointer"
            onClick={() => {
              navigate({
                pathname: "/auth/reset-password",
                search: `?type=${useEmail ? "email" : "phone"}&value=${
                  useEmail ? email : phone
                }`,
              });
            }}
          >
            Forgotten Password? Reset my password
          </span>
        </div>
      )}

      <div className="flex flex-col">
        <div className="my-5 w-full">
          <button
            type="submit"
            className={`w-full flex justify-center py-4 px-4 border border-transparent text-base font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 text-white ${
              isAuthenticated ? "cursor-not-allowed bg-gray-300" : "bg-blue-500"
            }`}
            disabled={isAuthenticated}
          >
            {isAuthenticated && (
              <AiOutlineLoading3Quarters className="h-6 w-6 animate-spin text-blue-400 mr-2" />
            )}{" "}
            {!showPasswordInput ? "Next" : "Login"}
          </button>
        </div>
      </div>
    </form>
  );
};

export default LoginForm;

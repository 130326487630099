import React, { useMemo, useRef } from "react";
import { AiOutlineLoading } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { useAppDispatch, useAppSelector } from "../../redux/hook";
import Img1 from "../../assets/images/2.png";
import Img2 from "../../assets/images/3.png";
import Img3 from "../../assets/images/5.png";
import Img4 from "../../assets/images/6.png";

const AuthLoader = () => {
  const dispatch = useAppDispatch();
  const { fromRoute } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();

  const init = useRef({ navigate, dispatch });

  React.useEffect(() => {
    const { navigate } = init.current;
    if (fromRoute) {
      if (["/auth/", "/auth/register"].includes(fromRoute)) navigate("/");
    }
  }, [fromRoute]);

  const settings = useMemo(
    () => ({
      arrows: false,
      infinite: true,
      slidesToShow: 2,
      slidesToScroll: 1,
      className: "center",
      centerPadding: "60px",
      swipeToSlide: true,
      autoplay: true,
      autoplaySpeed: 3500,
      pauseOnHover: true,
      // afterChange: function (index: number) {
      //   console.log(
      //     `Slider Changed to: ${index + 1}, background: #222; color: #bada55`
      //   );
      // },
    }),
    []
  );

  return (
    <div className="w-screen min-h-screen justify-center items-center flex">
      <div className="flex flex-col justify-center">
        <Slider
          {...settings}
          className="h-72 mx-2 lg:max-w-4xl lg:mx-auto lg:px-4 w-full py-6"
        >
          <div className="lg:h-64 lg:w-56 h-48 w-36 px-4 rounded-md">
            <img src={Img1} alt="img1" className="object-cover" />
          </div>
          <div className="lg:h-64 lg:w-56 h-48 w-36 px-4 rounded-md">
            <img src={Img2} alt="img2" className="object-cover" />
          </div>
          <div className="lg:h-64 lg:w-56 h-48 w-36 px-4 rounded-md">
            <img src={Img3} alt="img3" className="object-cover" />
          </div>
          <div className="lg:h-64 lg:w-56 h-48 w-36 px-4 rounded-md">
            <img src={Img4} alt="img4" className="object-cover" />
          </div>
        </Slider>
        <div className="flex items-center justify-center">
          <span className="lg:text-2xl text-md flex items-center mx-auto text-center">
            <AiOutlineLoading className="animate-spin h-5 w-5 text-tendo-active mr-2" />{" "}
            Getting things ready, 🍸 hold for some seconds.
          </span>
        </div>
      </div>
    </div>
  );
};

export { AuthLoader };

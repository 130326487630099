import { RadioGroup } from "@headlessui/react";
import { ExclamationIcon, InformationCircleIcon } from "@heroicons/react/solid";
import { message } from "antd";
import _ from "lodash";
import React, { useState } from "react";
import { HiOutlineMinusCircle, HiOutlinePlusCircle } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { toggleCartModal } from "../redux/actions/appActions";
import { addProductToCart } from "../redux/actions/cartActions";
import { useAppDispatch, useAppSelector } from "../redux/hook";
import { classNames } from "../utils/lib";
import { ICartProduct } from "../utils/types";

type Props = {};

const AddProductToCartForm = (props: Props) => {
  const dispatch = useAppDispatch();
  const [moreProfit, setMoreProfit] = useState(false);
  const [typedSpec, setTypedSpec] = useState("");
  const [profit, setProfit] = useState(0);
  const [qty, setQty] = useState(1);
  const [selectedSpec, setSelectedSpec] = useState<string[]>([]);
  const { orderProduct } = useAppSelector((state) => state.orders);
  const { cart } = useAppSelector((state) => state.cart);

  const navigate = useNavigate();

  const radioChange = (e: any, index: number) => {
    const tempSpec = [...selectedSpec];
    tempSpec[index] = e;
    setSelectedSpec(tempSpec);
  };

  const ChangeQty = (e: any) => {
    setQty(+e.target.value);
  };

  const inc = () => {
    setQty(qty + 1);
  };
  const dec = () => {
    if (qty > 1) {
      setQty(qty - 1);
    }
  };

  const addToCart = () => {
    const productObj: ICartProduct = {
      imageLink: orderProduct?.newImageServerLink,
      name: orderProduct?.product,
      productPrice: orderProduct?.wholesale.replace("&#8358;", ""),
      productQty: qty,
      productSKU: orderProduct?.skus,
      profitAdded: profit.toString(),
      spec:
        selectedSpec.length !== 0
          ? selectedSpec.join(" ")
          : !["", undefined, null].includes(typedSpec)
          ? typedSpec
          : "",
      supplierCost: orderProduct?.cost,
    };
    dispatch(addProductToCart(productObj));
    message.success("Product added to cart", 5);
    dispatch(toggleCartModal(false));
  };

  const orderNow = () => {
    const productObj: ICartProduct = {
      imageLink: orderProduct?.newImageServerLink,
      name: orderProduct?.product,
      productPrice: orderProduct?.wholesale.replace("&#8358;", ""),
      productQty: qty,
      productSKU: orderProduct?.skus,
      profitAdded: profit.toString(),
      spec:
        selectedSpec.length !== 0
          ? selectedSpec.join(" ")
          : !["", undefined, null].includes(typedSpec)
          ? typedSpec
          : "",
      supplierCost: orderProduct?.cost,
    };
    dispatch(addProductToCart(productObj));
    message.success("Product added to cart", 5);
    dispatch(toggleCartModal(false));
    navigate("/cart");
  };

  return (
    <div className="flex lg:justify-center">
      <div className="w-screen flex flex-col lg:justify-center flex-1 overflow-y-scroll">
        <div className="flex flex-col w-full">
          <div className="flex-1 p-2 bg-gray-100 my-2 rounded-md">
            <div className="flex items-start">
              <div className="flex-shrink-0 pt-0.5">
                <img
                  className="h-12 w-12 rounded-md"
                  src={orderProduct?.newImageServerLink}
                  alt=""
                />
              </div>
              <div className="ml-3 w-0 flex-1 flex flex-col">
                <span className="text-sm font-medium text-gray-900">
                  {orderProduct?.product}
                </span>
                <span className="mt-1 text-xs text-gray-500">
                  {orderProduct?.wholesale}
                </span>
              </div>
            </div>
          </div>

          {cart && cart?.products?.length >= 1 && (
            <div className="rounded-md bg-yellow-50 p-1.5 mb-2">
              <div className="flex">
                <div className="flex-shrink-0">
                  <ExclamationIcon
                    className="h-5 w-5 text-yellow-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3">
                  <span className="text-xs text-yellow-800">
                    Only add multiple products to cart if you are ordering
                    multiple products for the same customer otherwise checkout
                    as a single order.
                  </span>
                </div>
              </div>
            </div>
          )}

          <div className="rounded-md bg-blue-50 p-2">
            <div className="flex">
              <div className="flex-shrink-0">
                <InformationCircleIcon
                  className="h-5 w-5 text-blue-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3 flex-1 md:flex md:justify-between">
                <span className="text-sm text-blue-700">
                  You have added &#8358;{" "}
                  {parseFloat(profit.toString()).toFixed(2)} profit to {qty}{" "}
                  product{qty > 1 ? "s" : ""}
                </span>
              </div>
            </div>
          </div>

          <div className="mb-2 mt-4">
            <label htmlFor="profit" className="block text-sm font-medium">
              Enter your profit
            </label>
            <div className="relative rounded-md shadow-sm">
              <input
                type="number"
                name="profit"
                id="profit"
                required
                className="focus:ring-gray-300 focus:border-gray-300 block w-full pl-7 pr-12 py-2 sm:text-sm border-gray-300 border-2 rounded-md"
                value={profit}
                onChange={(e) => {
                  setProfit(Number(e.target.value));
                  if (
                    Number(e.target.value) >
                    parseInt(orderProduct?.wholesale.replace("&#8358;", ""))
                  ) {
                    setMoreProfit(true);
                  } else {
                    setMoreProfit(false);
                  }
                }}
                placeholder={`${profit.toFixed(2)}`}
              />
            </div>
            {moreProfit && (
              <span className={`text-xs font-medium text-yellow-500 mt-1`}>
                We advice you add profit up to 100% of product price.
              </span>
            )}
          </div>

          <div className="my-2">
            <label htmlFor="profit" className="block text-sm font-medium">
              Enter quantity
            </label>
            <div className="relative rounded-md py-1 flex-1">
              <input
                type="number"
                id="productQty"
                name="productQty"
                value={qty}
                className="focus:ring-tendo-active focus:border-tendo-active block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md relative"
                onChange={(e) => ChangeQty(e)}
              />
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                <HiOutlinePlusCircle
                  className="h-4 w-4 mx-1 cursor-pointer"
                  onClick={(e) => inc()}
                />

                <HiOutlineMinusCircle
                  onClick={(e) => dec()}
                  className="h-4 w-4 mx-1 cursor-pointer"
                />
              </div>
            </div>
          </div>

          {orderProduct?.productSpec && orderProduct?.productSpec.length > 1 ? (
            <div className="mb-2 mt-4">
              <label htmlFor="spec" className="block text-sm font-medium">
                Select product spec
              </label>

              {orderProduct?.productSpec.map(
                (spec: any, index: number, array: any[]) => (
                  <RadioGroup
                    value={selectedSpec[index]}
                    onChange={(value) => {
                      radioChange(value, index);
                    }}
                    className="mt-2"
                    key={index + 1}
                  >
                    <RadioGroup.Label>
                      {_.startCase(_.toLower(spec?.label))}
                    </RadioGroup.Label>
                    <div className="grid grid-cols-3 gap-3 sm:grid-cols-6 px-0.5">
                      {array[index]?.value?.split(",").map((option: any) => (
                        <RadioGroup.Option
                          key={option}
                          value={option.trim()}
                          className={({ active, checked }) =>
                            classNames(
                              "cursor-pointer focus:outline-none",
                              active
                                ? "ring-2 ring-offset-2 ring-indigo-500"
                                : "",
                              checked
                                ? "bg-indigo-600 border-transparent text-white hover:bg-indigo-700"
                                : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50",
                              "border rounded-md p-2 flex items-center justify-center text-xs font-medium uppercase sm:flex-1"
                            )
                          }
                        >
                          <RadioGroup.Label as="span">
                            {option.trim()}
                          </RadioGroup.Label>
                        </RadioGroup.Option>
                      ))}
                    </div>
                  </RadioGroup>
                )
              )}
            </div>
          ) : (
            <div className="mb-3">
              <label htmlFor="spec" className="block text-sm font-medium">
                Enter product spec
              </label>
              <div className="mt-2 relative rounded-md shadow-sm">
                <input
                  type="text"
                  id="spec"
                  autoComplete="spec"
                  className="focus:ring-sokoBlue focus:border-sokoBlue block w-full pl-7 pr-12 py-2 sm:text-sm border-gray-300 border-2 rounded-md placeholder:text-gray-200"
                  placeholder="Black small"
                  value={typedSpec}
                  onChange={(e) => {
                    setTypedSpec(e.target.value);
                  }}
                />
              </div>
            </div>
          )}

          <div className="flex space-x-3">
            <button
              type="button"
              className={`w-full flex justify-center py-4 px-4 text-base font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 border-2 border-blue-500 text-blue-500 hover:bg-blue-600 hover:text-white`}
              onClick={addToCart}
            >
              Add to cart
            </button>
            <button
              type="button"
              className={`w-full flex justify-center py-4 px-4 border border-transparent text-base font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 ${"bg-blue-500 text-white"}`}
              onClick={orderNow}
            >
              Order now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddProductToCartForm;

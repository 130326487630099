import { call, put, select, SelectEffect } from "redux-saga/effects";
import api from "../../services/api.service";
import { DELETE_PENDING_TRANSACTION, GET_USER_WALLET_DATA, REQUEST_PAYOUT } from "../../utils/constants";
import { getUserWalletDetails } from "../actions/walletActions";
import {
  setAvailableBalance,
  setFailedOrders,
  setSuccessfulOrders,
  setTotalCashOut,
  setTotalOrders,
  setTotalRevenue,
  setTransaction,
} from "../slice/wallet.slice";
import { RootState, store } from "../store";

function selectState<T>(selector: (s: RootState) => T): SelectEffect {
  return select(selector);
}

const selector = (state: RootState): ReturnType<typeof store.getState> => state;

function* walletSaga({ type, payload }: { type: string; payload: any }) {
  switch (type) {
    case GET_USER_WALLET_DATA:
      try {
        const data: Record<string, any> = yield call(
          api.getUserWalletData,
          payload
        );
        // console.log(data, "data"); //debug purposes
        if (data.success === true) {
          yield put(setTransaction(data.data.allTransactions));
          yield put(setTotalCashOut(data.data.totalCashOut));
          yield put(setTotalOrders(data.data.totalOrders));
          yield put(setTotalRevenue(data.data.totalProfitEarned));
          yield put(setAvailableBalance(data.data.totalAvailableBalance));
          yield put(setFailedOrders(data.data.cancelledOrders));
          yield put(setSuccessfulOrders(data.data.successfulOrders));
        }
      } catch (error) {
        console.log(error);
      }
      break;
    case REQUEST_PAYOUT:
      try {
        const state: ReturnType<typeof selector> = yield selectState(
          selector
        ) as unknown as ReturnType<typeof selector>;

        const user = state.auth.user;

        const data: Record<string, any> = yield call(
          api.requestProfitPayout,
          payload
        );

        if (data) {
          yield put(getUserWalletDetails(user?.username));
        }
      } catch (error) {
        console.log(error);
      }
      break;
      case DELETE_PENDING_TRANSACTION:
      const state: ReturnType<typeof selector> = yield selectState(
        selector
      ) as unknown as ReturnType<typeof selector>;

      try {
        const user = state.auth.user;
        const data: Record<string, any> = yield call(api.deletePendingTransaction, payload)

        if(data.success) {
          yield put(getUserWalletDetails(user?.username));
        }

      } catch (error) {
        console.log(error);
      }
      break;
    default:
      yield console.log("Wallet default");
      break;
  }
}

export default walletSaga;

import { ICart, IOrder, ITransaction, IUser } from "../utils/types";
import { NetworkService } from "./network.service";

class Api {
  private baseUrl: string;
  private network: NetworkService;
  constructor() {
    this.baseUrl =
      process.env.NODE_ENV === "production"
        ? "https://nigeria.main.tendoservices.dev/api/v2"
        : "http://localhost:5001/api/v2";
    this.network = new NetworkService(this.baseUrl);
  }

  public getSheetData = async (sheet: string, params?: Record<string, any>) => {
    const url = `sheet/data/${sheet}`;

    const response = await this.network.get({ url, params });
    return response.data;
  };

  public getPagedSheetData = async (
    sheet: string,
    params?: Record<string, any>
  ) => {
    const url = `/sheet/main/${sheet}`;
    const response = await this.network.get({ url, params });
    return response.data;
  };

  public getCategory = async (
    category: string,
    params?: Record<string, any>
  ) => {
    const url = `/sheet/category/${category}`;
    const response = await this.network.get({ url, params });
    return response.data;
  };

  public getUserWalletData = async (username: string) => {
    const url = `/sheet/wallet/${username}`;
    const response = await this.network.get({ url });
    return response.data;
  };

  public getUser = async (param: string, key: string) => {
    const url = `/users/${key}/${param}`;
    const response = await this.network.get({ url });
    return response.data;
  };

  public loginUser = async (args: {
    phone?: string;
    password?: string;
    email?: string;
  }) => {
    const { phone, password, email } = args;
    const url = `/users/auth/login`;
    const data = { phone, password, email };
    const response = await this.network.post(url, data);
    return response.data;
  };

  public registerUser = async (user: IUser) => {
    const url = `/users/auth/register`;
    const data = { ...user, name: user.fullName };
    const response = await this.network.post(url, data);
    return response.data;
  };

  public updateUserData = async (args: { action: string; user: IUser }) => {
    const { action, user } = args;
    const url = `/users/auth/update`;
    const data = { action, user };
    const response = await this.network.post(url, data);
    return response.data;
  };

  public downloadMedia = async (media: string) => {
    const data = { url: media };
    const url = `/sheet/media`;
    const response = await this.network.post(url, data);
    return response.data;
  };

  public searchForProducts = async (sheet: string, q: string) => {
    const url = `/sheet/search/${sheet}`;
    const params = { q };
    const response = await this.network.get({ url, params });
    return response.data;
  };

  public checkoutCart = async (cart: ICart) => {
    const url = `orders/cart/checkout`;
    const data = { cart };
    const response = await this.network.post(url, data);
    return response.data;
  };

  public getUserCustomers = async (username: string) => {
    const url = `/users/customers/${username}`;
    const response = await this.network.get({ url });
    return response.data;
  };

  public getCustomer = async (key: string, value: string) => {
    const url = `/customers/${key}/${value}`;
    const response = await this.network.get({ url });
    return response.data;
  };

  public getCouponCode = async (code: string, user: string) => {
    const url = `/orders/cart/coupon/claim/${code}`;
    const params = {
      user,
    };
    const response = await this.network.get({ url, params });
    return response.data;
  };

  public updateOrder = async (order: Partial<IOrder>) => {
    const url = `/orders/update`;
    const data = { order };
    const response = await this.network.put(url, data);
    return response.data;
  };

  public orderingProduct = async (order: IOrder) => {
    const url = `/newAppOrders`;
    const data = { order };
    const response = await this.network.post(url, data);
    return response.data;
  };

  public getOrderById = async (param: string) => {
    const url = `/orders/orderNumber/${param}`;
    const response = await this.network.get({ url });
    return response.data;
  };

  public addSharedProduct = async (product: Record<string, any>) => {
    const url = `/sharedProducts`;
    const data = { product };
    const response = await this.network.post(url, data);
    return response.data;
  };

  public deleteSharedProduct = async (id: number) => {
    const url = `/sharedProducts/${id}`;
    const response = await this.network.delete(url);
    return response.data;
  };

  public getTransaction = async (param: string, key: string) => {
    const url = `/resellers/profit-request/${key}/${param}`;
    const response = await this.network.get({ url });
    return response.data;
  };

  public deletePendingTransaction = async (data: ITransaction) => {
    const url = `/resellers/profit-request/${data?.id}`;
    const response = await this.network.put(url, data);
    return response.data;
  };

  public requestProfitPayout = async (profit: ITransaction) => {
    const url = `/resellers/profit-request`;
    const data = { profit };
    const response = await this.network.post(url, data);
    return response.data;
  };

  public requestOTPReset = async (args: { phone?: string; email?: string }) => {
    const { phone, email } = args;
    const url = `/users/otp/request`;
    const data = { phone, email, countryCode: "ng" };
    const response = await this.network.post(url, data);
    return response.data;
  };

  public requestOTP = async (args: { phone?: string; email?: string }) => {
    const { phone, email } = args;
    const url = `/users/auth/otp`;
    const data = { phone, email, countryCode: "ng" };
    const response = await this.network.post(url, data);
    return response.data;
  };

  public verifyOTP = async (args: {
    phone?: string;
    email?: string;
    code: string;
  }) => {
    const { phone, email, code } = args;
    const url = `/users/otp/verify`;
    const data = { phone, email, code };
    const response = await this.network.post(url, data);
    return response.data;
  };

  public getUserOrders = async (user: string) => {
    const url = `/orders/user/${user}`;
    const response = await this.network.get({ url });
    return response.data;
  };
}

export default new Api();

import React, { useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import { useAppSelector } from "../redux/hook";
import { formatNigeriaPhoneNumber } from "../utils/lib";

import "react-phone-input-2/lib/bootstrap.css";
import { useDispatch } from "react-redux";
import { setToggleLogin, setToggleRegister } from "../redux/actions/appActions";
import {
  OTPRequest,
  registerUser,
  setUpdateUserDetails,
  verifyOTP,
} from "../redux/actions/authActions";
import { message, notification } from "antd";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline";
import { ArrowRightIcon } from "@heroicons/react/solid";

interface Props {
  refCode?: string;
}

const RegisterForm = ({ refCode }: Props) => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Record<string, any>>();
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [dob, setDob] = useState("");
  const [isMale, setIsMale] = useState(false);
  const [otpCode, setOTPCode] = useState("");
  const [showOtherDetails, setShowOtherDetails] = useState(false);
  const [showPinInput, setShowPinInput] = useState(false);
  const [isOTPValid, setIsOTPValid] = useState(false);
  const [hidePassword, setHidePassword] = useState(false);
  const { isAuthenticated, authStatus, user } = useAppSelector(
    (state) => state.auth
  );
  const { toggleRegister } = useAppSelector((state) => state.app);
  const navigate = useNavigate();

  const init = useRef({
    navigate,
    dispatch,
  });

  const otpSent = useMemo(
    () =>
      authStatus?.success &&
      !authStatus.isError &&
      authStatus.error.toLowerCase() === "otp sent",
    [authStatus?.error, authStatus?.isError, authStatus?.success]
  );
  const otpVerify = useMemo(
    () =>
      authStatus?.success &&
      !authStatus.isError &&
      authStatus.error.toLowerCase() === "otp successfully verified",
    [authStatus?.error, authStatus?.isError, authStatus?.success]
  );
  const invalidOTP = useMemo(
    () =>
      !authStatus?.success &&
      authStatus?.isError &&
      authStatus.error.toLowerCase() === "invalid otp code",
    [authStatus?.error, authStatus?.isError, authStatus?.success]
  );
  const updateDone = useMemo(
    () =>
      authStatus?.success &&
      !authStatus.isError &&
      authStatus.error.toLowerCase() === "update successful",
    [authStatus?.error, authStatus?.isError, authStatus?.success]
  );

  const isError = useMemo(
    () => authStatus && authStatus.isError && !authStatus.success,
    [authStatus]
  );

  useEffect(() => {
    if (
      isError &&
      ![
        "otp sent",
        "enter password",
        "otp successfully verified",
        "update successful",
        "no password",
      ].includes(authStatus?.error?.toLowerCase() as string)
    ) {
      notification.error({
        message: "Error occurred",
        description: authStatus?.error,
      });
    }
  }, [authStatus?.error, isError]);

  useEffect(() => {
    const { dispatch } = init.current;
    if (otpSent) {
      notification.success({
        message: "OTP Sent",
        description: "OTP has been sent to your phone number and/or mail",
        duration: 3,
      });
      setShowOtherDetails(false);
      setShowPinInput(true);
      setIsOTPValid(false);
    }
    if (otpVerify) {
      setShowOtherDetails(true);
      setShowPinInput(false);
      setIsOTPValid(false);
    }

    if (invalidOTP) {
      setIsOTPValid(true);
    }
    if (updateDone) {
      setIsOTPValid(false);
      notification.success({
        message: "Account successfully created",
        duration: 5,
      });
      dispatch(setToggleRegister(false));
    }
  }, [otpSent, otpVerify, invalidOTP, updateDone]);

  useEffect(() => {
    const el = document.getElementById("referralCode") as HTMLInputElement;
    if (refCode) {
      el.value = refCode ?? "TENDO";
    }
  }, [refCode]);

  useEffect(() => {
    const { navigate } = init.current;
    if (!toggleRegister) {
      navigate("/auth/onboard");
    }
  }, [toggleRegister]);

  const showPassword = () => {
    setHidePassword(!hidePassword);
  };
  // 8084507001 test phone number

  const resendCode = () => {
    dispatch(OTPRequest({ phone, email }));
  };

  const skipOTP = () => {
    setShowOtherDetails(true);
    setShowPinInput(false);
    setIsOTPValid(false);
  };

  const skipDetails = () => {
    dispatch(setToggleRegister(false));
  };

  const onSignUpSubmit = (data: any) => {
    data.phone = phone;

    if (["", undefined, null].includes(data?.phone)) {
      message.error("Phone number can not be empty", 5);
      return;
    }
    if (showOtherDetails && !showPinInput) {
      const updateObj = {
        ...user,
        dateOfBirth: new Date(dob).toLocaleDateString("en-GB"),
        gender: isMale ? "male" : "female",
        referralCode: data.referralCode,
      };
      dispatch(
        setUpdateUserDetails({
          user: { ...updateObj },
          action: "update/profile",
        })
      );
    } else if (!showOtherDetails && showPinInput) {
      dispatch(verifyOTP({ code: otpCode, phone, email }));
    } else {
      dispatch(registerUser(data));
    }
  };

  return (
    <form onSubmit={handleSubmit(onSignUpSubmit)}>
      <div className="text-left mb-4">
        <h1 className="text-3xl">
          {!showOtherDetails && !showPinInput
            ? "Create your account 👌🏾"
            : showPinInput && !showOtherDetails
            ? "Verify account 🙌🏾"
            : "Your details 😎"}
        </h1>
        <p className="text-sm font-semibold text-gray-400 mt-3">
          Already a reseller?{" "}
          <span
            className="ml-1 text-blue-500 cursor-pointer"
            onClick={() => {
              dispatch(setToggleRegister(false));
              dispatch(setToggleLogin(true));
            }}
          >
            Sign in here
          </span>
        </p>
      </div>

      {!showOtherDetails && !showPinInput && (
        <>
          <div className="mb-3">
            <label htmlFor="email" className="block text-sm font-medium">
              Your full name
            </label>
            <div className="mt-2 relative rounded-md shadow-sm">
              <input
                type="text"
                id="fullName"
                autoComplete="fullName"
                className="focus:ring-sokoBlue focus:border-sokoBlue block w-full pl-7 pr-12 py-4 sm:text-sm border-gray-300 border-2 rounded-md"
                placeholder="John Doe"
                {...register("fullName", { required: true })}
              />
            </div>
            {errors.fullName?.type === "required" && (
              <span className={`block text-xs text-red-600 mt-2 font-semibold`}>
                Please enter your full legal name
              </span>
            )}
          </div>

          <div className="mb-3">
            <label htmlFor="phone" className="block text-sm font-medium">
              Your phone number
            </label>
            <div className="mt-2 relative rounded-md shadow-sm">
              <PhoneInput
                country={"ng"}
                onlyCountries={["ng"]}
                enableSearch={true}
                inputStyle={{ width: "100%", height: 56 }}
                inputClass="focus:ring-gray-300 focus:border-gray-300 sm:text-sm border-gray-300 border-2 py-2"
                placeholder="+234 20xxxxxx"
                value={phone}
                countryCodeEditable={false}
                onChange={(value) => {
                  formatNigeriaPhoneNumber({
                    phone: value,
                    cb: setPhone,
                    errorCb: setPhoneError,
                  });
                }}
              />
            </div>
            {phoneError && (
              <span className="text-red-500 mt-1 text-xs font-semibold">
                Invalid phone number
              </span>
            )}
          </div>

          <div className="mb-3">
            <label htmlFor="email" className="block text-sm font-medium">
              Your email
            </label>
            <div className="mt-2 relative rounded-md shadow-sm">
              <input
                type="email"
                id="email"
                autoComplete="email"
                className="focus:ring-gray-300 focus:border-gray-300 block w-full pl-7 pr-12 py-4 sm:text-sm border-gray-300 border-2 rounded-md"
                placeholder="example@mail.com"
                {...register("email", { required: true })}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            {errors.email?.type === "required" && (
              <span className={`block text-xs text-red-600 mt-2 font-semibold`}>
                Please enter your email
              </span>
            )}
          </div>

          <div className="mb-5">
            <label htmlFor="email" className="block text-sm font-medium">
              Password
            </label>
            <div className="mt-2 relative rounded-md shadow-sm">
              <input
                type={hidePassword ? "text" : "password"}
                id="password"
                className="focus:ring-sokoBlue focus:border-sokoBlue block w-full pl-7 pr-12 py-4 sm:text-sm border-gray-300 border-2 rounded-md relative"
                {...register("password", { required: true })}
              />
              <div
                className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                onClick={showPassword}
              >
                {hidePassword ? (
                  <EyeOffIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                ) : (
                  <EyeIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                )}
              </div>
            </div>
            {errors.password?.type === "required" && (
              <span className={`block text-xs text-red-600 mt-2 font-semibold`}>
                Please enter your password
              </span>
            )}
          </div>
        </>
      )}

      {showPinInput && !showOtherDetails && (
        <div className="mb-5">
          <div className="mb-6">
            <span className="text-sm">
              We have sent you a code to phone number as SMS or email, enter it
              below to continue.
            </span>
          </div>
          <label htmlFor="email" className="block text-sm font-medium">
            Enter the 6 digit code you received
          </label>
          <div className="mt-2 relative rounded-md shadow-sm">
            <input
              type="text"
              id="password"
              className="focus:ring-sokoBlue focus:border-sokoBlue block w-full pl-7 pr-12 py-4 sm:text-sm border-gray-300 border-2 rounded-md"
              onChange={(e) => setOTPCode(e.target.value)}
              maxLength={6}
              value={otpCode}
              placeholder="xxxx"
              inputMode="numeric"
            />
          </div>
          <div className="flex justify-between items-center py-2">
            {isOTPValid && (
              <span className="text-red-500 text-sm text-font-medium">
                Invalid OTP Code
              </span>
            )}
            <span
              className="text-center text-sm font-semibold text-blue-500 flex  justify-end cursor-pointer"
              onClick={resendCode}
            >
              Resend code
            </span>
          </div>
        </div>
      )}

      {showOtherDetails && (
        <>
          <div className="mb-3 py-2">
            <label htmlFor="email" className="block text-sm font-medium">
              Your gender
            </label>
            <div className="mt-1 flex space-x-4">
              <div className="flex items-center">
                <input
                  id="male"
                  name="gender"
                  type="radio"
                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                  onChange={() => setIsMale(true)}
                />
                <label
                  htmlFor="male"
                  className="ml-3 block text-sm font-medium text-gray-700"
                >
                  Male
                </label>
              </div>
              <div className="flex items-center">
                <input
                  id="female"
                  name="gender"
                  type="radio"
                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                  onChange={() => setIsMale(false)}
                />
                <label
                  htmlFor="female"
                  className="ml-3 block text-sm font-medium text-gray-700"
                >
                  Female
                </label>
              </div>
            </div>
          </div>

          <div className="mb-3">
            <label htmlFor="email" className="block text-sm font-medium">
              Your date of birth
            </label>
            <div className="mt-2 relative rounded-md shadow-sm">
              <input
                type="date"
                id="dob"
                autoComplete="dob"
                className="focus:ring-gray-300 focus:border-gray-300 block w-full pl-7 pr-12 py-4 sm:text-sm border-gray-300 border-2 rounded-md"
                placeholder="DD/MM/YYYY"
                onChange={(e) => setDob(e.target.value)}
              />
            </div>
            {errors.email?.type === "required" && (
              <span className={`block text-xs text-red-600 mt-2 font-semibold`}>
                Please enter your email
              </span>
            )}
          </div>

          <div className="mb-3">
            <label htmlFor="email" className="block text-sm font-medium">
              Who referred you?
            </label>
            <div className="mt-2 relative rounded-md shadow-sm">
              <input
                type="text"
                id="referralCode"
                className="focus:ring-gray-300 focus:border-gray-300 block w-full pl-7 pr-12 py-4 sm:text-sm border-gray-300 border-2  rounded-md"
                placeholder="Enter referral code"
                defaultValue={refCode}
                {...register("referralCode", { required: true })}
              />
            </div>
            <span
              className={`block text-xs ${
                errors.referralCode?.type === "required"
                  ? "text-red-600"
                  : "text-green-600"
              } mt-2 font-semibold`}
            >
              Enter the referral code of the one who referred you. If none,
              enter tendo.
            </span>
          </div>
        </>
      )}

      <div className="flex">
        <div className="my-2 w-full">
          <button
            type="submit"
            className={`w-full flex justify-center py-4 px-4 border border-transparent text-base font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 text-white ${
              isAuthenticated ? "cursor-not-allowed bg-gray-200" : "bg-blue-500"
            }`}
            disabled={isAuthenticated ? true : false}
          >
            {isAuthenticated && (
              <AiOutlineLoading3Quarters className="h-6 w-6 animate-spin text-blue-400 mr-2" />
            )}
            {!showOtherDetails && !showPinInput
              ? "Create Account"
              : !showOtherDetails && showPinInput
              ? "Verify"
              : "Continue"}
          </button>
        </div>
      </div>
      {!showOtherDetails && !showPinInput ? (
        <p className="text-center text-sm text-gray-400 mt-2">
          By clicking on create an account <br />
          you have agreed to our{" "}
          <a
            href="https://rebrand.ly/tendo-policy"
            target="_blank"
            rel="noopener noreferrer"
            className="ml-1 text-blue-500"
          >
            Terms &amp; Conditions
          </a>
        </p>
      ) : !showOtherDetails && showPinInput ? (
        <span
          className="text-center text-sm text-blue-400 mt-2 flex items-center justify-center"
          onClick={skipOTP}
        >
          Skip, will do later <ArrowRightIcon className="h-3 w-3 ml-2" />
        </span>
      ) : (
        <span
          className="text-center text-sm text-blue-400 mt-2 flex items-center justify-center"
          onClick={skipDetails}
        >
          I will do this maybe later <ArrowRightIcon className="h-3 w-3 ml-2" />
        </span>
      )}
    </form>
  );
};

export default RegisterForm;

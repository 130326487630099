import React, { Fragment, Suspense, useRef } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import AddProductToCartForm from "../components/AddProductToCartForm";
import Loader from "../components/common/Loader";
import Modal from "../components/common/Modal";
import LoginForm from "../components/LoginForm";
import RegisterForm from "../components/RegisterForm";
import ResetPassword from "../components/ResetPassword";
import Onboarding from "../pages/Auth/components/Onboarding";
import {
  setSignOutModal,
  setToggleRegister,
  toggleCartModal,
} from "../redux/actions/appActions";
import { authenticateUser, logoutUser } from "../redux/actions/authActions";
import { clearCart } from "../redux/actions/cartActions";
import { useAppDispatch, useAppSelector } from "../redux/hook";
import { setFromRoute } from "../redux/slice/auth.slice";
import { setUsePrompt } from "../redux/slice/cart.slice";
import { lazyWithRetry } from "../utils/lib";

interface Props {}

const Home = lazyWithRetry(
  () => import(/* webpackChunkName: "home" */ "../pages/Home/Home")
);
const Categories = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "categories" */ "../pages/Categories/Categories"
    )
);
const Category = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "category" */ "../pages/Categories/components/CategoriesProducts"
    )
);
const Explore = lazyWithRetry(
  () => import(/* webpackChunkName: "explore" */ "../pages/Explore/Explore")
);
const Promo = lazyWithRetry(
  () => import(/* webpackChunkName: "promo" */ "../pages/Promo/Promo")
);
const PromoProducts = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "promoProduct" */ "../pages/Promo/components/PromoProductList"
    )
);
const Accounts = lazyWithRetry(
  () => import(/* webpackChunkName: "accounts" */ "../pages/Accounts/Accounts")
);
const Product = lazyWithRetry(
  () => import(/* webpackChunkName: "product" */ "../components/ProductDetails")
);
const Order = lazyWithRetry(
  () => import(/* webpackChunkName: "order" */ "../components/OrderForm")
);
const ConfirmOrder = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "confirmOrder" */ "../components/OrderConfirm")
);
const UserOrders = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "userOrders" */ "../pages/Accounts/components/UserOrders"
    )
);
const OrderDetails = lazyWithRetry(
  (): any =>
    import(
      /* webpackChunkName: "orderDetails" */ "../pages/Accounts/components/OrderDetails"
    )
);
const Wallet = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "wallet" */ "../pages/Accounts/components/Wallet"
    )
);
const Settings = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "settings" */ "../pages/Accounts/components/Settings"
    )
);
const RequestPayOut = lazyWithRetry(
  (): any =>
    import(
      /* webpackChunkName: "requestPayout" */ "../pages/Accounts/components/PayOutRequest"
    )
);
const ReferAFriend = lazyWithRetry(
  (): any =>
    import(
      /* webpackChunkName: "referAFriend" */ "../pages/Accounts/components/ReferAFriend"
    )
);
const DeliveryPrices = lazyWithRetry(
  (): any => import("../pages/Accounts/components/DeliveryLocation")
);
// const SharedProductsList = lazyWithRetry(
//   () =>
//     import(
//       /* webpackChunkName: "sharedProductList" */ "../pages/Accounts/components/SharedProducts"
//     )
// );
const Cart = lazyWithRetry(
  (): any => import(/* webpackChunkName: "Cart" */ "../pages/Cart/Cart")
);
const CartCustomer = lazyWithRetry(
  (): any =>
    import(
      /* webpackChunkName: "Cart" */ "../pages/Cart/components/AddCustomer"
    )
);
const Checkout = lazyWithRetry(
  () => import("../pages/Cart/components/Checkout")
);
const Auth = lazyWithRetry(() => import("../pages/Auth/Auth"));
const TendoAcademy = lazyWithRetry(
  (): any =>
    import(
      /* webpackChunkName: "referAFriend" */ "../pages/Accounts/components/TendoAcademy"
    )
);

const AppLayout = (props: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    toggleLogin,
    toggleRegister,
    toggleSignOut,
    toggleCartModal: openCartModal,
  } = useAppSelector((state) => state.app);
  const { user } = useAppSelector((state) => state.auth);
  const { promptUser } = useAppSelector((state) => state.cart);

  const token = localStorage.getItem("resellerToken-ng-reseller") ?? null;
  const refLink = new URLSearchParams(location.search);
  const refCode = refLink?.get("refCode") ?? "";
  const currentPath = location.pathname;

  const init = useRef({ dispatch, navigate, currentPath });
  React.useEffect(() => {
    const { dispatch, currentPath } = init.current;
    if (!token && !user) {
      dispatch(setToggleRegister(true));
    } else if (token && !user) {
      dispatch(authenticateUser(token));
      if (["/auth/", "/auth/register"].includes(currentPath)) {
        dispatch(setFromRoute(currentPath));
      }
    }
  }, [token, user]);

  React.useEffect(() => {
    const { navigate } = init.current;
    if (toggleLogin) navigate("/auth");
    if (toggleRegister)
      navigate(`/auth/register${refCode ? `?refCode=${refCode}` : ""}`);
  }, [refCode, toggleLogin, toggleRegister]);

  return (
    <Fragment>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/categories" element={<Categories />} />
          <Route path="/categories/:categoryName" element={<Category />} />
          <Route path="/explore" element={<Explore />} />
          <Route path="/promotions" element={<Promo />} />
          <Route path="/promotions/:promo" element={<PromoProducts />} />
          <Route path="/accounts" element={<Accounts />} />
          <Route path="cart">
            <Route index element={<Cart />} />
            <Route path="customer" element={<CartCustomer />} />
            <Route path="checkout" element={<Checkout />} />
          </Route>
          <Route path="/auth" element={<Auth />}>
            <Route index element={<LoginForm />} />
            <Route path="register" element={<RegisterForm />} />
            <Route path="reset-password" element={<ResetPassword />} />
            <Route path="onboard" element={<Onboarding />} />
          </Route>
          <Route path="/accounts/myorders" element={<UserOrders />} />
          <Route
            path="/accounts/order/:orderNumber"
            element={<OrderDetails />}
          />
          <Route path="/accounts/settings" element={<Settings />} />
          <Route path="/accounts/wallet" element={<Wallet />} />
          <Route
            path="/accounts/wallet/request-payout"
            element={<RequestPayOut />}
          />
          <Route path="/accounts/referral" element={<ReferAFriend />} />
          {/* <Route path="/accounts/shared" element={<SharedProductsList />} /> */}
          <Route
            path="/accounts/delivery-prices"
            element={<DeliveryPrices />}
          />
          <Route path="/accounts/learn" element={<TendoAcademy />} />
          <Route path="/product/:productName" element={<Product />} />
          <Route path="/product/order" element={<Order />} />
          <Route path="/order/confirm" element={<ConfirmOrder />} />
        </Routes>
      </Suspense>

      <Modal
        show={toggleSignOut}
        setShow={() => dispatch(setSignOutModal(!toggleSignOut))}
      >
        <div className="mt-2">
          <p className="text-sm text-gray-500">Are sure you want sign out?</p>
        </div>

        <div className="mt-4 flex justify-between">
          <button
            type="button"
            className="inline-flex justify-center px-4 py-2 text-xs md:text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
            onClick={() => dispatch(setSignOutModal(false))}
          >
            No, keep me signed in
          </button>
          <button
            type="button"
            className="inline-flex justify-center px-4 py-2 text-xs md:text-sm font-medium text-blue-900 bg-gray-100 border border-transparent rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
            onClick={() => {
              dispatch(logoutUser(!toggleSignOut));
            }}
          >
            Yes, sign me out
          </button>
        </div>
      </Modal>
      <Modal
        show={promptUser}
        setShow={() => dispatch(setUsePrompt(!promptUser))}
      >
        <div className="mt-2">
          <span className="text-sm text-gray-500">
            You have an active cart. Do you still want to add product?
          </span>
        </div>

        <div className="mt-4 flex justify-between">
          <button
            type="button"
            className="inline-flex justify-center px-4 py-2 text-xs md:text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
            onClick={() => dispatch(clearCart())}
          >
            No, reset cart
          </button>
          <button
            type="button"
            className="inline-flex justify-center px-4 py-2 text-xs md:text-sm font-medium text-blue-900 bg-gray-100 border border-transparent rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
            onClick={() => {
              dispatch(setUsePrompt(!promptUser));
            }}
          >
            Yes, add to cart
          </button>
        </div>
      </Modal>
      <Modal
        show={openCartModal}
        setShow={() => dispatch(toggleCartModal(!openCartModal))}
      >
        <AddProductToCartForm />
      </Modal>
    </Fragment>
  );
};

export default AppLayout;
